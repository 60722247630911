import gql from 'graphql-tag'

//---------------------------- HomePage Queries ---------------------------------

export const homepageQuery = gql`
query homeQuery($alias : JSON, $lang: String) {
  homepages (sort: "displayPosition", where : { alias : $alias }, locale: $lang) {
    id
    speech    
    menuName
    locale 
    alias    
    pageType{
      ... on ComponentHomepageNavigation{
        __typename
        pathName
        pageHeader
      }
      ... on ComponentHomepageDialogBox{
        __typename      
        type
        textList
        mapName
      }
    }
    menuIcon{
      id
      url
    }
  }
}
`

//---------------------------- Faq Page Queries ---------------------------------

export const faqCategoryQuery = gql`
query faqCategoryQuery($lang: String, $location: String) {
  faqCategories(sort: "displayOrder", locale: $lang, where : { kioskLocationCode_contains : $location}) {
    id
    categoryName
    displayType
    displayOrder
    locale
  }
}
`

export const faqQuery = gql`
query faqQuery($lang: String, $location: String) {
  faqs (sort: "id", locale: $lang, where : { kioskLocationCode_contains : $location}) {
    id
    category
    locale
    showMap
    mapName
    question
    answer
  }
}
`
//---------------------------- Map Page Queries ---------------------------------

export const floorQuery = gql`
query floorQuery($lang: String, $location: String) {
  floors (sort: "floorNumber:asc", locale: $lang, where : { kioskLocationCode_contains: $location }){ 
    id
    floorName
    floorNumber
    defaultLocation
    speech
    locale
    floorImage {
      id
      url
    }
  }
}
`

export const mapQuery = gql`
query mapQuery($lang: String,  $location: String) {
  maps(sort: "displayPosition:asc", locale: $lang, where : { kioskLocationCode_contains: $location }){
    id
    mapName
    mapFloor
    speech
    displayPosition
    locale
    mapImage {
      id
      url
    }
  }
}
`


//---------------------------- Additional Photos Query ---------------------------------

export const departmentQuery = gql`
query directoryQuery {
  directories {
    id
    contactName
    contactNumber
    contactDepartment
  }
}
`