<template>
  <v-row no-gutters class="mb-n15">
    <v-col cols="3" aling="center" justify="center">
      <v-card height="775" flat class="containerBorder mx-3">
        <v-card-title class="d-flex justify-center font-weight-bold primary--text">
          MAP DIRECTORY
        </v-card-title>
        <v-divider class="mx-3"></v-divider>
        <v-card height="695" class="overflow-y-auto" flat>
          <v-list text>
            <v-list-item-group color="white">
              <v-list-item v-for="(item, i) in maps" :key="i"
                @click="selectImage(item.mapImage, item.mapFloor, item.speech, item.mapName)">
                <v-list-item-content>
                  <v-btn min-height="45" @click="selectedMap = item.mapName" class="pa-0 ma-0 text-wrap white--text"
                    v-bind:color="item.mapName === selectedMap ? 'secondary' : 'primary'">{{
                      item.mapName
                    }}</v-btn>
                </v-list-item-content>
              </v-list-item>
            </v-list-item-group>
          </v-list>
        </v-card>
      </v-card>
    </v-col>
    <v-col cols="9">
      <v-tabs active-class="tabClass" background-color="primary" centered height="70" dark v-model="active_tab">
        <v-tab v-for="(floor, index) in $t('floors')" :key="index"
          @click="selectImage(floor.floorImage.url, floor.floorNum, floor.speech, floor.floorName)"
          class="text-h6 pa-5">
          {{ floor.floorName }}
        </v-tab>
      </v-tabs>
      <v-row no-gutters>
        <v-col align="center" cols="12">
          <v-img :src="url" width="85%" class="mt-2">
          </v-img>
        </v-col>
        <!-- <v-col cols="1" align-self="center">
          <v-btn color="primary" class="my-2" outlined width="120">
            <v-icon color="primary" left>mdi-toilet</v-icon>
            Restroom
          </v-btn>          
          <v-btn color="primary" class="my-2" outlined width="120">
            <v-icon color="primary" left>mdi-elevator-passenger</v-icon>
            Elevator
          </v-btn>
          <v-btn color="primary" class="my-2" outlined width="120">
            <v-icon color="primary" left>mdi-stairs</v-icon>
            Stairs
          </v-btn>
        </v-col> -->
      </v-row>
    </v-col>
  </v-row>
</template>

<script>
import { mapGetters } from 'vuex'

export default {
  name: 'maps',
  data() {
    return {
      active_tab: 0,
      url: '',
      selectedMap: ''
    }
  },
  computed: {
    ...mapGetters(['getFloors', 'getMaps', 'getCMSlink', 'getKioskProfile', 'getSessionId']),
    maps() {
      let temp = []
      this.$i18n.t('maps').forEach(map => {
        temp.push(map)
      })
      return temp
    }
  },
  methods: {
    selectImage(mapPath, mapFloor, speech, mapName) {
      let date = new Date()
      this.active_tab = mapFloor;
      this.url = mapPath
      if (speech !== undefined) {
        this.$store.dispatch('avatarSpeak', speech)
        // Touch Usage
        this.$store.dispatch('addSessionDataToStrapi', {
          applicationId: this.getKioskProfile.data().applicationId,
          actionType: 'Touch',
          applicationSessionId: this.getSessionId,
          timeStamp: date.toISOString(),
          sessionPayload: {
            module: "Maps",
            action: "View Map",
            response: mapName,
            timeStamp: date.toISOString()
          }
        })
      }
    },
  },
  filters: {
    trimLength(val) {
      if (val.length < 25) {
        return val
      }
      return `${val.substring(0, 23)}...`
    }
  },
  mounted() {
    this.getFloors.forEach(floor => {
      if (floor.defaultLocation) {
        this.selectImage(floor.floorImage.url, floor.floorNumber)
      }
    })
  }

}
</script>
<style>
.tabClass {
  background-color: #2c6b87;
  color: white !important;
  border-top-left-radius: 15px;
  border-top-right-radius: 15px;
}
</style>