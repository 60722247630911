<template>
  <v-dialog v-model="getImageViewer.show" width="60%" persistent>
    <v-card width="100%">
      <v-row no-gutters>
        <v-col cols="12">
          <v-card-title>
            <v-row>
              <v-col align="center" cols="11">
                <span class="text-h5 pl-16 font-weight-bold">{{ getImageViewer.name }}</span>
              </v-col>
              <v-col cols="1" align="center" class="pa-0">
                <v-progress-circular @click="closeDialog()" :rotate="180" :size="50" :width="7"
                  :value="closePercentage" color="error" class="my-2">
                  <v-icon large color="error"> mdi-close </v-icon>
                </v-progress-circular>
              </v-col>
            </v-row>
          </v-card-title>
          <v-divider class="mx-2"></v-divider>
          <v-col cols="12" class="mb-10">
            <v-img :src="getImageViewer.url" width="98%"></v-img>
          </v-col>
        </v-col>
      </v-row>
    </v-card>
  </v-dialog>
</template>

<script>
import { mapGetters } from 'vuex';

export default {
  name: "imageViewer",
  data() {
    return {
      closePercentage: 0,
      timeout: 0,
      intervalObj: 0
    }
  },
  computed: {
    ...mapGetters(['getImageViewer', 'getIsSpeaking'])
  },
  watch: {
    getIsSpeaking(newVal) {
      if (this.getImageViewer.show) {
        if (!newVal) {
          clearTimeout(this.timeout)
          this.intervalObj = setInterval(() => {
            if (this.closePercentage === 100) {
              clearInterval(this.intervalObj);
              this.closePercentage = 0;
              this.$store.commit("closeImageViewer");
            }
            this.closePercentage += 2;
          }, 250);
        }
      } else {
        this.closePercentage = 0
        clearInterval(this.intervalObj);
        clearTimeout(this.timeout)
      }
    }
  },

  methods: {
    closeDialog() {
      this.$store.commit('closeImageViewer');
      this.closePercentage = 0
      clearInterval(this.intervalObj);
      clearTimeout(this.timeout);
    }
  }
}
</script>

<style scoped></style>
