<template>
  <v-row no-gutters >
    <v-col align="center" class="mb-2">
      <!-- <span class="text-h2 font-weight-bold grey--text text--darken-3">Circuit Court, Ingham County</span> -->
      <v-img src="@/assets/logo.png" contain width="450"></v-img>
    </v-col>
  </v-row>
</template>

<script>
export default {
  name: 'languageBar',
  methods: {
    changeLanguage(language) {
      this.$store.commit('setTouchCount', this.getTouchCount + 1)

      switch (language) {
        case 'en-US':
          this.$i18n.locale = 'en'
          this.$store.commit('setCurrentLanguage', 'en-US');
          this.$store.dispatch('avatarSpeak', 'I am now speaking in english');
          this.$store.dispatch('setDefaults', 'en')
          break;
        case 'es-US':
          //this.$store.commit('clearAvatarSpeech');
          this.$i18n.locale = 'es'
          this.$store.commit('setCurrentLanguage', 'es-US');
          this.$store.dispatch('avatarSpeak', 'Estoy hablando en español');
          this.$store.dispatch('setDefaults', 'es')

          break;
        case 'nv-US':
          this.$store.commit('setTextBubbleText', 'Dinék’ehjí yáti’ bíhoosh’aah, t’ah hanááshidííłkiń.')
          //this.$store.commit('clearAvatarSpeech');
          break;
        case 'vi-US':
          this.$store.commit('setTextBubbleText', 'Chúng tôi đang học tiếng Việt.  Xin quý vị vui lòng trở lại sau này.')
          break;
        default:
          break;
      }
    }
  }
}
</script>
<style></style>