<template>
  <v-row no-gutters justify="center" align="center">
    <v-col cols="6">
      <avatar :initialWidth="avatarWidth"></avatar>
    </v-col>
    <v-col cols="6">
      <v-row justify="center" align="center">
        <v-col align="center" cols="12">
          <span class="text-h3">
            {{ statusHeader }}
          </span>
        </v-col>
        <v-col align="center" cols="8">
          <v-progress-linear color="success" :indeterminate="intermediate" value="100" rounded class="mb-2" height="15">
          </v-progress-linear>
          <span class="text-h5">
            {{ status }}
          </span>

        </v-col>
        <!-- <v-col cols="8" align="center">
          <v-btn v-if="getDataLoaded" class="text-h5" color="success" @click="updateText()" x-large>
            Continue
          </v-btn>

        </v-col> -->
      </v-row>
    </v-col>
    <v-col cols="12" align="end" class="px-15">
      <span class="text-h6 px-5">{{ location }}</span>
      <v-icon @click="$router.push('/rec')" class="mr-10" size="50" color="grey lighten-1">
        mdi-cog-outline
      </v-icon>

      <v-icon @click="$store.dispatch('logout')" size="50" color="error lighten-2">
        mdi-power-standby
      </v-icon>
    </v-col>
  </v-row>
</template>

<script>
import avatar from '@/components/avatar.vue'

import { mapGetters } from 'vuex'

export default {
  name: 'loadData',
  data() {
    return {
      statusHeader: 'Fetching My Intelligence!',
      status: 'Initiating..',
      intermediate: true,
      location: ''
    }
  },
  components: {
    avatar,
  },
  computed: {
    ...mapGetters(['appDimensions', 'getKioskProfile', 'getAvatarLoaded', 'getDataLoaded', 'getAvatarDetails']),
    avatarWidth() {
      return this.appDimensions.width / 2 - 50
    }
  },
  watch: {
    getAvatarLoaded(newVal) {
      if (newVal) {
        this.$store.commit('setDefaultBubbleText', true)
        this.$store.dispatch('avatarSpeak', "Give me a moment, I am fetching my intelligence.")
        this.initLoadData();
        this.location = this.getKioskProfile.data().name
      }
    }
  },
  methods: {
    initLoadData() {
      const URI = this.getKioskProfile.data().cmsLink
      console.log("URI :", URI);
      this.$store.dispatch('createApolloConnection', URI + '/graphql')
        .then(response => {
          this.status = response;
          this.$store.dispatch('fetchFloors')
            .then(response => {
              this.status = response;
              this.$store.dispatch('fetchMaps')
                .then(response => {
                  this.status = response;
                  this.$store.dispatch("fetchDepartmentContacts")
                    .then(response => {
                      this.status = response
                      this.$store.dispatch('fetchFaqs')
                        .then(response => {
                          this.status = response;
                          this.$store.dispatch('fetchFaqCategory')
                            .then(response => {
                              this.status = response;
                              this.$store.dispatch('fetchHomePage')
                                .then(response => {
                                  this.status = response
                                  this.$store.commit('setDataLoaded', true);
                                  this.status = 'Data Loaded';
                                  this.$store.dispatch('setDefaults')
                                  this.$store.commit('setEnableAllRecognition', true);
                                  this.status = 'Enabled All Recognition';
                                  this.$store.commit('setEnableSpeechRecognition', true);
                                  this.status = 'Enabled Speech Recogniton';
                                  this.$store.commit('setEnableFaceDetection', true);
                                  this.status = 'Enabled Face Detection';
                                  this.intermediate = false
                                  this.status = 'Fetch Complete!'
                                  this.statusHeader = "Completed!"
                                  setTimeout(() => {this.updateText()}, 1500)
                                })
                            })
                        })
                    })
                })
            })
        })
    },
    updateText() {
      this.$router.push('/')
      this.$store.commit('setDefaultBubbleText', true)
      this.$store.commit('setTextBubbleText', "Hi, I’m here to help you. Tap the microphone button, and ask me ‘What can you do?’")
    }
  },
  mounted() {
    //this.loadAvatar()
  }
}

</script>
